import * as React from 'react'
import Layout from '../components/Layout/Layout'
import Privacy from '../components/Page/Privacy'

const privacyPage = () => {
	return (
		<Layout>
			<section className="flex flex-row items-start flex-wrap p-4 md:pt-12 md:pb-16 bg-boswell-handwriting">
				<div className="md:w-1/2 mx-auto">
					<Privacy />
				</div>
			</section>
		</Layout>
	)
}

export default privacyPage
